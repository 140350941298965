<template>
    <div>
        <b-row>
            <b-col cols="12">
                
      <b-alert :show="errors.length > 0" v-for="(item, index) in errors" :key="index" variant="danger" dismissible>
        {{item.message}}</b-alert>
            </b-col>
            
        </b-row>
        <b-row v-if="showNoLocationAvailable == true" class="mt-2">
            <b-col cols="12">
                
      <b-alert :show="true" variant="warning" dismissible>No devices available to load data</b-alert>
            </b-col>
            
        </b-row>
        
        <b-row v-if="isLoading" align-h="center" class="mt-5" align-v="center">
          <b-spinner style="width: 5rem; height: 5rem;" label="Large Spinner"></b-spinner>
        </b-row>
        <b-row align-h="center" class="mt-5" v-if="!isLoading">
            <b-col cols="12" sm="6" md="4" lg="3">
                <b-card class="secondary-background text-center">
                    <h4>{{batteryInfo.percentage}}%</h4>
                    <p>{{batteryInfo.volts}} Volt</p>
                    <img src="@/assets/battery-img.svg" alt="">
                    <p>Status</p>
                    <b-button disabled variant="gray">{{batteryInfo.status}}</b-button>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { bus } from '../main';
import { mapActions } from 'vuex'
export default {
    data: () => ({
        errors: [],
        isLoading: false,
        batteryInfo: { 
                percentage: null,
                volts: null,
                status: 'N/A'
            },
      showNoLocationAvailable: false
    }),
    created(){
    bus.$on('changedLocation', () => {
    this.doApiCall()

    })
        this.doApiCall()
    },
    methods: {
        ...mapActions(['getBatterySummary']),
        doApiCall(){
            this.isLoading = true
            this.errors = []
            this.getBatterySummary()
            .then((response)=>{
            if (response.data !== null) {
                this.batteryInfo = { 
                    percentage: response.data.percentage.toFixed(2),
                    volts: response.data.volts,
                    status: response.data.status
                }
            }
            else{
              this.showNoLocationAvailable = true
            }
            this.isLoading = false
            })
            .catch(() => {
                
            this.isLoading = false
            this.errors.push({
              message: 'Failed to load data. Please refresh and try again.'
            })
            })
        }
    }
}
</script>